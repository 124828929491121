import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import ContactSection from "../components/home/ContactSection"


import {
  Typography,
  Container
} from "@mui/material"

const NotFound = () => {
  return (
    <Layout>

      <Container>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "clash display, sans-serif",
            fontSize: { xs: "1.5rem", md: "3rem" },
            color: "black",
            margin: "50px auto",
            textAlign: "center",
            lineHeight: "150%",
            marginTop: "130px",
            fontWeight: "600",
            maxWidth:"650px"
          }}
        >
                <h1>404: Page Not Found</h1>
                <p>
        <Link to="/blog/">Check our latest articles</Link>
      </p>

        </Typography>

</Container>
<ContactSection>
  
</ContactSection>

    </Layout>
  )
}

export default NotFound